<template>
  <div class="hexagon-wrapper" @click="handleClick">
    <div class="hexagon-wrapper__gradient-line theme-fortune-line"></div>
    <div :class="['hexagon theme-hexagon-border', { 'hexagon--timer-expired': !isTimerRunning }]">
      <svg width="0" height="0">
        <defs>
          <linearGradient id="bggrad">
            <stop offset="0%" stop-color="#D9D9D9" />
            <stop offset="100%" stop-color="#D9D9D9" />
          </linearGradient>
          <linearGradient id="brdgrad">
            <stop offset="0%" stop-color="#7F91C8FF" />
            <stop offset="100%" stop-color="#7F91C8FF" />
          </linearGradient>
        </defs>
      </svg>
      <svg viewBox="0 0 100 100" preserveAspectRatio="none">
        <path
          class="hexagon__border"
          d="M25,7 L75,7 93,50 75,93 25,93 7,50z"
          vector-effect="non-scaling-stroke"
        />
      </svg>
      <span class="hexagon__text">{{ title.length > 0 ? title : formattedTime }}</span>
    </div>
    <template v-if="isShowMiniWheelSvg">
      <SmallWheelDisabledIcon v-if="isTimerRunning" class="hexagon__mini-wheel-svg" />
      <template v-else>
        <SmallWheelEnabledIcon class="hexagon__mini-wheel-svg hexagon__mini-wheel-svg--active" />
        <div class="hexagon__button">
          <span class="hexagon__button-title">{{ _("spin_wheel") }}</span>
        </div>
      </template>
    </template>
    <!--    <xmas-decoration-->
    <!--      v-if="isMobile"-->
    <!--      :style="{-->
    <!--        position: 'absolute',-->
    <!--        zIndex: 0,-->
    <!--        top: 0,-->
    <!--        left: 0,-->
    <!--        width: '100%',-->
    <!--        height: '2rem',-->
    <!--        transform: 'translateY(1rem)',-->
    <!--      }"-->
    <!--    />-->
  </div>
</template>

<script>
import SmallWheelDisabledIcon from "../assets/icons/mini-wheel-disabled.svg";
import SmallWheelEnabledIcon from "../assets/icons/mini-wheel-ready.svg";

import { mapGetters, mapState } from "vuex";
// import XmasDecoration from "@/components/xmas-decoration/xmas-decoration.vue";

export default {
  name: "WheelFortuneTimerButton",
  components: {
    // XmasDecoration,
    SmallWheelDisabledIcon,
    SmallWheelEnabledIcon,
  },
  props: {
    isShowMiniWheelSvg: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    onClick: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      isTimerRunning: false,
      timeRemaining: 0,
      timer: null,
    };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.app.isMobile,
    }),
    ...mapGetters({
      wheelFortuneReadyTimestamp: "user/wheelFortuneReadyTimestamp",
      wheelFortuneIsActive: "user/wheelFortuneIsActive",
      _: "l10n/translate",
    }),
    timeDifference() {
      const wheelFortuneReadyTimestamp = this.wheelFortuneReadyTimestamp;
      const currentTime = Date.now() / 1000;
      return Math.floor(wheelFortuneReadyTimestamp - currentTime);
    },

    formattedTime() {
      const hours = Math.floor(this.timeRemaining / 3600);
      const minutes = Math.floor((this.timeRemaining % 3600) / 60);
      const seconds = this.timeRemaining % 60;

      return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    },
  },

  async created() {
    this.isTimerRunning = !this.title;
  },

  async mounted() {
    this.countdown();
  },

  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    handleClick() {
      if (!this.isTimerRunning) {
        this.onClick();
      }
    },
    countdown() {
      this.timeRemaining = Math.max(0, this.timeDifference);
      if (this.timeRemaining > 0) {
        this.timeRemaining = Math.max(0, this.timeDifference);
        this.timer = setInterval(() => {
          this.timeRemaining -= 1;
          if (this.timeRemaining <= 1) {
            clearInterval(this.timer);
            this.isTimerRunning = false;
          }
        }, 1000);
      } else {
        clearInterval(this.timer);
        this.isTimerRunning = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
* {
  font-family: $base-font-family;
}

.hexagon-wrapper {
  position: relative;
  width: 100%;

  &__gradient-line {
    &::before {
      content: "";
      position: absolute;
      top: 43%;
      left: 0;
      width: 100%;
      height: 2px;
      background: linear-gradient(
        to right,
        rgba(51, 51, 51, 0) 0%,
        rgb(127, 145, 200),
        rgba(51, 51, 51, 0) 100%
      );
    }
  }
}

.hexagon {
  position: relative;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  height: 34px;
  min-width: 120px;
  padding: 12px 24px;
  margin: 12px 0 4px 0;
  float: left;
  font-weight: bold;
  text-align: center;

  &--timer-expired {
    cursor: pointer;
  }

  &__mini-wheel-svg {
    position: absolute;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, 71%) scale(1.1);

    &--active {
      //box-shadow: 1px 1px 1px 1px #0b5412;
    }
  }

  &__button {
    cursor: pointer;
    position: relative;
    background-image: url($path + "get-bonus-btn.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 138px;
    height: 38px;
    top: -6px;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, 50%);
  }

  &__button-title {
    position: absolute;
    top: 50%;
    left: 35%;
    width: 100%;
    word-break: break-word;
    inline-size: 100px;
    text-wrap: balance;
    font-family: $second-font-family;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    @include adaptive-font(14, 12);
    color: white;
    transform: translate(-28%, -50%);
    text-shadow: 0 2px 1px rgba(0, 0, 0, 0.65);
  }

  svg {
    position: absolute;
    height: 70%;
    width: 90%;
    top: 0px;
    left: 5px;
    z-index: -1;
  }

  &__border {
    stroke: url(#brdgrad);
    stroke-width: 2;
  }

  &__text {
    display: block;
    margin-top: 8px;
    padding: 8px;
    transform: translateY(-50%);
    font-family: $second-font-family;
    text-transform: uppercase;
    font-weight: bold;
    color: $text-color;
    white-space: nowrap;
  }
}
</style>
