<template>
  <div class="wheel-fortune__page">
    <div class="wheel-fortune__button-info" @click="toggleInformation">
      <InformationIcon />
    </div>
    <WinModal :winning-sum="winningSum" :on-click="handleBackClick" :is-visible="isWinning" />
    <Container
      v-if="isReady"
      :is-hidden-brands-mobile="true"
      :is-hidden-jackpots-mobile="true"
      :is-disabled-swiper-mobile="true"
    >
      <template #jackpots="{ isMobile }">
        <Logo
          :is-hide="isMobile && hasJackpots"
          :is-wide="!hasJackpots"
          :is-full="isMobile && !hasJackpots"
        />

        <Jackpots :jackpots="jackpots" />
        <MobileFooter v-if="isMobile" />
      </template>
      <template v-if="hasUser && isHeaderBonusDescriptionVisible" #bonuses-description>
        <ThemeBonusDescription />
      </template>

      <template v-if="hasUser" #fortune-button>
        <WheelFortuneTimerButton
          :title="_('exit')"
          :is-show-mini-wheel-svg="false"
          :on-click="handleBackClick"
        />
        <!--        <xmas-decoration-->
        <!--          v-if="!isMobile"-->
        <!--          :style="{-->
        <!--            position: 'absolute',-->
        <!--            zIndex: 1,-->
        <!--            top: '0',-->
        <!--            left: 0,-->
        <!--            width: '100%',-->
        <!--            height: '2rem',-->
        <!--            transform: 'translateY(1rem)',-->
        <!--          }"-->
        <!--        />-->
      </template>
      <template v-if="!isWheelUnavailable" #games>
        <div class="boards">
          <template v-if="isMobile">
            <div
              :class="[
                'wheel-fortune__wrapper-boards',
                {
                  'wheel-fortune__wrapper-boards--info-visible': isInformationLandscapeVisible,
                },
              ]"
              class="wheel-fortune__wrapper-boards"
            >
              <div class="wheel-fortune__outer-wrapper wheel-fortune__outer-wrapper--left">
                <h2 class="wheel-fortune__title wheel-fortune__title">
                  {{ _("how_it_works").toUpperCase() }}
                </h2>
                <div class="wheel-fortune__text-wrapper">
                  <pre class="wheel-fortune__text">
                      {{ wheelSettings?.rules?.general }}
                    </pre
                  >
                </div>
              </div>
              <div class="wheel-fortune__outer-wrapper wheel-fortune__outer-wrapper--right">
                <h2 class="wheel-fortune__title wheel-fortune__title">
                  {{ _("daily_wheel_rules").toUpperCase() }}
                </h2>
                <div class="wheel-fortune__text-wrapper">
                  <pre class="wheel-fortune__text">
                  {{ wheelSettings?.rules?.daily }}
                  </pre>
                </div>
              </div>
            </div>
          </template>
          <div class="wheel-fortune__wheel-wrapper">
            <div
              class="wheel-fortune__outer-wrapper wheel-fortune__outer-wrapper--left mobile-hidden"
            >
              <h2 class="wheel-fortune__title wheel-fortune__title">
                {{ _("how_it_works").toUpperCase() }}
              </h2>
              <div class="wheel-fortune__text-wrapper">
                <pre class="wheel-fortune__text">
                 {{ wheelSettings?.rules?.general }}
               </pre
                >
              </div>
            </div>
            <WheelFortuneComponent :on-click="afterSpinCallback" />
            <div
              class="wheel-fortune__outer-wrapper wheel-fortune__outer-wrapper--right mobile-hidden"
            >
              <h2 class="wheel-fortune__title wheel-fortune__title">
                {{ _("daily_wheel_rules").toUpperCase() }}
              </h2>
              <div class="wheel-fortune__text-wrapper">
                <pre class="wheel-fortune__text">
                   {{ wheelSettings?.rules?.daily }}
                  </pre
                >
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else #games>
        <div class="wheel-fortune__wheel-unavailable">
          <h3>The Wheel of Fortune is not available now.</h3>
          <h3>Come a little later, it will be available soon.</h3>
        </div>
      </template>
      <template #footer="{ isMobile }">
        <div v-if="!isMobile" class="wheel-fortune__footer">
          <div class="wheel-fortune__footer-row wheel-fortune__footer-row--second">
            <div class="wheel-fortune__footer-side">
              <LanguageSelect />
            </div>
          </div>
        </div>
      </template>
    </Container>
  </div>
</template>

<script>
import loadAllData from "@/mixins/load-all-data";
import routeMixin from "@/mixins/route";
import { mapGetters, mapState } from "vuex";
import Logo from "@/components/Header/Logo.vue";
import MobileFooter from "@/components/Footer/MobileFooter.vue";
import Jackpots from "@/components/Jackpots/Jackpots.vue";
import Container from "@/components/Containers/Container.vue";
import WheelFortuneTimerButton from "@/modules/WheelFortune/components/WheelFortuneTimerButton.vue";
import WheelFortuneComponent from "@/modules/WheelFortune/components/WheelFortune.vue";
import { fetchFortuneWheelSettings } from "@/modules/WheelFortune";
import WinModal from "@/modules/WheelFortune/components/WinModal.vue";
import InformationIcon from "@/modules/WheelFortune/assets/icons/information.svg";
import ThemeBonusDescription from "@/components/theme-bonus-description/theme-bonus-description.vue";
import { themeService } from "@/services/ThemeService/ThemeService";
import LanguageSelect from "@/components/Helpers/language-select.vue";
// import XmasDecoration from "@/components/xmas-decoration/xmas-decoration.vue";

export default {
  name: "WheelFortune",
  components: {
    // XmasDecoration,
    LanguageSelect,
    ThemeBonusDescription,
    WinModal,
    WheelFortuneTimerButton,
    WheelFortuneComponent,
    InformationIcon,
    Container,
    Jackpots,
    MobileFooter,
    Logo,
  },
  mixins: [loadAllData, routeMixin],
  data() {
    return {
      wheelSettings: null,
      winningSum: null,
      isWinning: false,
      isInformationLandscapeVisible: false,
      isWheelUnavailable: false,
    };
  },
  computed: {
    ...mapGetters({
      jackpots: "jackpots/slots",
      hasJackpots: "jackpots/hasSlots",
      currentProvider: "providers/currentItem",
      wheelFortuneIsActive: "user/wheelFortuneIsActive",
      wheelFortuneTimeIsNotExpired: "user/wheelFortuneTimeIsNotExpired",
      _: "l10n/translate",
    }),
    isHeaderBonusDescriptionVisible() {
      return themeService.isHeaderBonusInfoVisible();
    },

    ...mapState({
      isMobile: (state) => state.app.isMobile,
      isReady({ tags, games, brands, l10n }) {
        return (
          games.items !== null &&
          tags.rawItems !== null &&
          brands.rawItems !== null &&
          l10n.current !== null &&
          l10n.items !== null
        );
      },
    }),
  },

  async beforeMount() {
    this.isWinning = false;
    await this.loadRequestsData(true);
    const settings = await fetchFortuneWheelSettings();

    if (!this.wheelFortuneIsActive || this.wheelFortuneTimeIsNotExpired) {
      return this.resetRoute();
    }

    if (!settings || !settings?.sectors?.length > 0) {
      this.isWheelUnavailable = true;
    }

    this.wheelSettings = settings;

    this.stopPageLoader();
  },

  methods: {
    afterSpinCallback(sum) {
      this.winningSum = sum;
      this.isWinning = true;
    },

    toggleInformation(event) {
      event.stopPropagation();
      this.isInformationLandscapeVisible = !this.isInformationLandscapeVisible;
    },

    handleBackClick() {
      this.redirectRoute("/");
    },
  },
};
</script>
<style scoped lang="scss">
* {
  font-family: $base-font-family;
}

@media (max-width: 1024px) {
  :deep .home__wheel-fortune-wrapper,
  .home__wheel-fortune-wrapper--is-fortune-page {
    top: 0;
  }

  :deep .home__section .home__section--brands {
    display: none;
  }
}

.wheel-fortune {
  $r: &;

  &__wheel-unavailable {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    font-family: $second-font-family !important;
    @include adaptive-font(24, 16);
    color: white;
    text-align: center;

    h3 {
      padding: 0 20px;
      margin: 0;
    }
  }

  &__button-info {
    display: none;
    color: white;
  }

  &__outer-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    &--right {
      left: -12px;

      #{$r}__text {
        margin-left: 25px;
      }

      #{$r}__title {
        margin-left: auto;
        padding-left: 28px;
      }

      #{$r}__text-wrapper {
        position: relative;
        border-image: linear-gradient(to left, #b57e10, #fff3a6, #f9df7b) 1;
        text-align: right;
        height: 100%;

        &:before {
          position: absolute;
          content: "";
          top: 0;
          left: -24px;
          width: 15%;
          background: black;
          height: 100%;
        }
      }
    }

    &--left {
      right: -12px;

      #{$r}__title,
      #{$r}__text {
        margin-right: 48px;
      }

      #{$r}__text-wrapper {
        position: relative;
        display: flex;
        height: 100%;
        width: 100%;
        margin-right: 16px;
        border-image: linear-gradient(to right, #b57e10, #fff3a6, #f9df7b) 1;
        text-align: left;

        &:before {
          position: absolute;
          content: "";
          top: 0;
          right: -36px;
          width: 15%;
          background: black;
          height: 100%;
        }
      }
    }
  }

  &__title {
    color: white;
    font-size: $font-lg-size;
    font-family: $second-font-family;
  }

  &__text {
    text-align: left;
    white-space: pre-line;
    font-size: $font-sm-size;
    color: $text-bright-color;
    opacity: 0.7;
  }

  &__text-wrapper {
    padding: 4px 15px 40px 15px;
    max-width: 400px;
    max-height: 200px;
    border-width: 2px;
    border-style: solid;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    background: black;
  }

  &__footer {
    z-index: 1;
    width: 100%;
    height: 37px;
    display: flex;
    flex-direction: column;
    background-color: $bg-black-color;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  &__footer-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 0 $footer-padding;

    &--second {
      flex: 1;
      height: $footer-2row-height;
      border-top: $footer-border-bottom-width solid $border-gray-color;
    }
  }

  &__footer-center {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
  }

  &__footer-side {
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 2;
  }

  &__footer-button {
    height: 30px;
    font-size: $font-sm-size;
    text-transform: uppercase;

    &--lang {
      width: 60px;
    }
  }

  &__page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $bg-black-color;
    background-image: radial-gradient(
        ellipse 150% 50% at top,
        fade-out($bg-dark-color, 0.6) 0%,
        $null-color 100%
      ),
      radial-gradient(
        ellipse 150% 50% at bottom,
        fade-out($bg-dark-color, 0.6) 0%,
        $null-color 100%
      ),
      radial-gradient(
        ellipse 150% 5% at top,
        fade-out($bg-contrast-color, 0.75) 0%,
        $null-color 100%
      ),
      radial-gradient(
        ellipse 150% 5% at bottom,
        fade-out($bg-contrast-color, 0.75) 0%,
        $null-color 100%
      );
    position: fixed;
  }

  &__wheel-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    position: absolute;
    bottom: -17%;
    left: 50%;
    transform: translateX(-50%);
  }

  &__line {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    width: 100%;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      background: linear-gradient(
        to right,
        rgba(51, 51, 51, 0) 0%,
        rgb(127, 145, 200),
        rgba(51, 51, 51, 0) 100%
      );
    }
  }

  &__button-text {
    display: block;
    margin-top: 10px;
    margin-right: 14px;
    padding: 8px;
    transform: translateY(-50%);
    font-family: $second-font-family;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 20px;
    text-shadow: 0 2px 1px rgba(114, 106, 106, 0.65);
    color: $text-color;
    white-space: nowrap;
  }

  &__back-button-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  @media (max-width: 1280px) {
    &__wheel-wrapper {
      transform: translateX(-50%) scale(0.95);
    }
  }

  @media (max-width: 1024px) {
    :deep .jackpots {
      display: none !important;
    }

    &__wrapper-boards {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 15%;
    }

    &__outer-wrapper {
      left: unset;
      right: unset;
      width: 100%;

      #{$r}__text-wrapper {
        margin: 0;
        max-width: 100%;
        width: auto;

        &:before {
          content: none;
        }
      }

      &--left {
        #{$r}__text-wrapper {
          &:before {
            content: none;
          }
        }

        #{$r}__title {
          margin: 0 auto;
        }
      }

      &--right {
        #{$r}__text-wrapper {
          &:before {
            content: none;
          }
        }

        #{$r}__text {
          margin-left: 0;
        }

        #{$r}__title {
          padding-left: 0;
          margin: 0 auto;
        }
      }
    }

    &__title {
      margin: 0 auto;
    }

    &__text-wrapper {
      padding: 0 10px;
      max-width: 100%;
    }

    &__wheel-wrapper {
      position: absolute;
      display: flex;
      justify-content: center;
      left: unset;
      top: 70%;
      transform: scale(0.75);
    }
  }

  @media (max-width: 780px) {
    &__wheel-wrapper {
      transform: scale(0.7);
      //bottom: -30%;
    }
  }

  @media (max-width: 546px) {
    &__wheel-wrapper {
      transform: scale(0.5);
    }
  }

  @media screen and (max-width: 1024px) and (orientation: landscape) {
    &__button-info {
      position: fixed;
      z-index: 4;
      display: block;
      bottom: 0;
      left: 1%;
      padding: 0 20px 20px 20px;
    }

    &__wrapper-boards {
      display: none;

      &--info-visible {
        display: flex;

        #{$r}__text-wrapper {
          border: none;
          background: transparent;
          font-size: 8px;
          box-shadow: none;
        }

        #{$r}__outer-wrapper {
          position: absolute;
          width: auto;

          &--left {
            top: 10%;
            left: 0;

            #{$r}__text {
              margin-right: 0;
            }
          }

          &--right {
            right: 0;
            top: 10%;
          }
        }

        #{$r}__text-wrapper {
          max-width: 200px;
        }

        #{$r}__title {
          display: none;
        }
      }
    }

    &__wheel-wrapper {
      transform: scale(0.5);
      top: 52%;
    }
  }
}
</style>
