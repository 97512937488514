<template xmlns="http://www.w3.org/2000/svg">
  <Container v-if="isReady">
    <template #header="{ isMobile }">
      <!--      <xmas-decoration-->
      <!--        v-if="!isMobile"-->
      <!--        :style="{-->
      <!--          position: 'absolute',-->
      <!--          zIndex: 2,-->
      <!--          top: '100px',-->
      <!--          left: 0,-->
      <!--          width: '100%',-->
      <!--          height: '2rem',-->
      <!--          transform: 'translateY(1rem)',-->
      <!--        }"-->
      <!--      />-->
      <div v-if="isMobile" class="home__header">
        <MobileHeader variant="home" />
        <MobileSearch />
      </div>
    </template>

    <template v-if="hasUser && bonus" #present>
      <take-bonus-popup />
    </template>

    <template #jackpots="{ isMobile }">
      <Logo
        :is-hide="isMobile && hasJackpots"
        :is-wide="!hasJackpots"
        :is-full="isMobile && !hasJackpots"
      />
      <Jackpots :jackpots="jackpots" />
      <div v-if="!hasJackpots && isMobile" class="sign-logo-footer sign-logo-footer--home"></div>

      <MobileFooter v-if="isMobile" />
    </template>
    <template v-if="hasUser && isHeaderBonusDescriptionVisible" #bonuses-description>
      <ThemeBonusDescription />
    </template>

    <template v-if="hasUser && wheelFortuneIsActive" #fortune-button>
      <WheelFortuneTimerButton :is-show-mini-wheel-svg="true" :on-click="goToWheelFortunePage" />
    </template>

    <template #games>
      <Games />
    </template>

    <template #afterGames="{ isMobile }">
      <DesktopMenu v-if="!isMobile" />
    </template>

    <template #brands>
      <Brands />
    </template>

    <template #footer="{ isMobile }">
      <!--      <xmas-decoration-->
      <!--        v-if="!isMobile"-->
      <!--        :style="{-->
      <!--          position: 'absolute',-->
      <!--          zIndex: 2,-->
      <!--          bottom: '120px',-->
      <!--          left: 0,-->
      <!--          width: '100%',-->
      <!--          height: '2rem',-->
      <!--          transform: 'translateY(1rem)',-->
      <!--        }"-->
      <!--      />-->
      <DesktopFooter v-if="!isMobile" />
    </template>

    <template #after>
      <BonusModal />
      <SuccessOrErrorModal :is-visible="isSuccessOrErrorModalShown" />
      <BuyTimeModal :is-show-modal="isModalBuyTimeShown" />
    </template>
  </Container>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import { upperFirst } from "lodash-es";

// Mixins
import loadAllData from "@/mixins/load-all-data";
import updateData from "@/mixins/update-data";
import routeMixin from "@/mixins/route";
// Components
import Container from "@/components/Containers/Container";
import BonusModal from "@/components/Modal/BonusModal";
import DesktopFooter from "@/components/Footer/DesktopFooter.vue";
import MobileHeader from "@/components/Header/MobileHeader.vue";
import MobileFooter from "@/components/Footer/MobileFooter.vue";
import DesktopMenu from "@/components/Menu/DesktopMenu.vue";
import Jackpots from "@/components/Jackpots/Jackpots.vue";
import Brands from "@/components/Navigation/Brands.vue";
import Games from "@/components/Game/Games.vue";
import Logo from "@/components/Header/Logo";
import MobileSearch from "@/components/Header/MobileSearch";
import TakeBonusPopup from "@/components/TakeBonusPopup/TakeBonusPopup.vue";
import BuyTimeModal from "@/components/Modal/BuyTimeModal.vue";
import SuccessOrErrorModal from "@/components/Modal/SuccessOrErrorModal.vue";
import WheelFortuneTimerButton from "@/modules/WheelFortune/components/WheelFortuneTimerButton.vue";
import ThemeBonusDescription from "@/components/theme-bonus-description/theme-bonus-description.vue";
import { themeService } from "@/services/ThemeService/ThemeService";
// import XmasDecoration from "@/components/xmas-decoration/xmas-decoration.vue";

export default {
  name: "Home",

  components: {
    // XmasDecoration,
    ThemeBonusDescription,
    WheelFortuneTimerButton,
    SuccessOrErrorModal,
    BuyTimeModal,
    MobileSearch,
    Container,
    BonusModal,
    TakeBonusPopup,
    DesktopFooter,
    MobileHeader,
    MobileFooter,
    DesktopMenu,
    Jackpots,
    Brands,
    Games,
    Logo,
  },

  mixins: [loadAllData, updateData, routeMixin],

  computed: {
    ...mapGetters({
      jackpots: "jackpots/slots",
      hasJackpots: "jackpots/hasSlots",
      currentProvider: "providers/currentItem",
      hasUser: "user/hasUser",
      wheelFortuneIsActive: "user/wheelFortuneIsActive",
      _: "l10n/translate",
    }),
    isHeaderBonusDescriptionVisible() {
      return themeService.isHeaderBonusInfoVisible();
    },

    ...mapState({
      currentBrandId: (state) => state.brands.currentItemId,
      isSuccessOrErrorModalShown: (state) => state.ui.isSuccessOrErrorModalShown,
      isModalBuyTimeShown: (state) => state.ui.isBuyTimeModalShown,
      bonus: (state) => {
        const { item } = state.bonus;
        if (!item || !item.bonus || !item.available) {
          return null;
        }
        return {
          name: upperFirst(item.bonus.stringId),
          amount: item.bonus.amount,
        };
      },
      isReady({ tags, games, brands, l10n }) {
        return (
          games.items !== null &&
          tags.rawItems !== null &&
          brands.rawItems !== null &&
          l10n.current !== null &&
          l10n.items !== null
        );
      },
    }),
  },

  async created() {
    /** Load data of all requests! */
    await this.loadRequestsData(true);

    if (this.currentProvider && this.currentProvider.gamesCount < 2) {
      const currentProvider = this.providers.find((provider) => provider.gamesCount > 1);

      this.setCurrentMenuIdToAllGames();
      this.setCurrentBrandId(null);
      this.setCurrentProviderId(currentProvider ? currentProvider.id : 0);
    }

    this.stopPageLoader();
    this.startUpdateDataInterval();
  },

  beforeDestroy() {
    this.stopUpdateDataInterval();
  },

  methods: {
    goToWheelFortunePage() {
      this.goToFortune();
    },
    ...mapMutations({
      setCurrentBrandId: "brands/setCurrentItemId",
      setCurrentProviderId: "providers/setCurrentItemId",
      setCurrentMenuIdToAllGames: "tags/setCurrentItemIdToDefault",
    }),
  },
};
</script>
<style scoped lang="scss">
.home {
  &__header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: $m_header-size - 1;
    z-index: 1000;
    background-color: $bg-black-color;
    background-image: radial-gradient(
        ellipse 50% 150% at left,
        fade-out($bg-dark-color, 0.6) 0%,
        $null-color 100%
      ),
      radial-gradient(ellipse 50% 150% at right, fade-out($bg-dark-color, 0.6) 0%, $null-color 100%);
  }
}
</style>
