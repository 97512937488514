<template>
  <div ref="header" class="mobile-header" :class="'mobile-header--variant:' + variant">
    <slot name="jackpots" :isJackpotsShowed="isJackpotsShowed" />

    <div v-if="hasUser && isBalanceShown && !isSweepstakesEnabled" class="mobile-header__balance">
      {{ $f.formatMoney($f.toMoney(balance)) }} {{ currency }}
    </div>
    <div v-if="hasUser && isSweepstakesEnabled" class="mobile-header__sweepstakes">
      <div class="mobile-header__sweepstakes-item">
        <div class="mobile-header__sweepstakes-title">
          {{ _("entries") }}
        </div>
        <div class="mobile-header__sweepstakes-value">
          {{ $f.formatMoney($f.toMoney(sweepstakesBalance?.entries)) }}
        </div>
      </div>
      <div class="mobile-header__sweepstakes-item">
        <div class="mobile-header__sweepstakes-title">{{ _("total-win") }} ({{ currency }})</div>
        <div class="mobile-header__sweepstakes-value">
          {{ $f.formatMoney($f.toMoney(sweepstakesBalance?.totalWin)) }}
        </div>
      </div>
    </div>

    <div v-if="isDemoModeActive" class="mobile-header__demo uppercase">
      {{ _("free.spins") }}
    </div>
    <mob-navigation
      v-model="isMenuShowed"
      v-bind="navAttrs"
      @toggle-jackpots="handleToggleJackpots"
    />
    <template v-if="isSweepstakesEnabled && variant === 'game'">
      <mob-navigation-sweepstakes :is-jackpots-showed="isJackpotsShowed" />
    </template>

    <mob-menu v-model="isMenuShowed" />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

// Components
import MobMenu from "@/components/Menu/MobileMenu";
import MobNavigation from "@/components/Menu/MobileNavigation";
import MobNavigationSweepstakes from "@/components/Menu/MobileNavigationSweepstakes";
import { getObjectValueByMap, validateProp } from "@/utils/validator";
import { get } from "lodash-es";

const VARIANTS = ["home", "game"];
const DEFAULT_VARIANT = "home";

export default {
  name: "MobileHeader",

  components: {
    MobMenu,
    MobNavigation,
    MobNavigationSweepstakes,
  },

  props: {
    variant: {
      type: String,
      default: "button",
      validator: (value) => validateProp(value, VARIANTS),
    },
  },

  data: () => ({
    isMenuShowed: false,
    isJackpotsShowed: false,

    variantMap: {
      home: {
        isBalanceShown: true,
      },

      game: {
        navigation: {
          buttons: ["back", "jackpots", "fullscreen", "hide"],
          translucent: true,
          compact: true,
        },
      },
    },
  }),

  computed: {
    ...mapState({
      isSweepstakesEnabled: (state) => {
        return get(state, "lobby.params.sweepstakes", false);
      },
    }),
    ...mapGetters({
      _: "l10n/translate",
      balance: "user/balance",
      hasUser: "user/hasUser",
      currency: "user/currency",
      isDemoModeActive: "app/isDemoModeActive",
      sweepstakesBalance: "user/sweepstakesBalance",
    }),

    navAttrs() {
      return getObjectValueByMap(this.variantMap, this.variant, "navigation", DEFAULT_VARIANT);
    },

    isBalanceShown() {
      return getObjectValueByMap(this.variantMap, this.variant, "isBalanceShown", DEFAULT_VARIANT);
    },
  },

  methods: {
    changeMenuState(value = true) {
      this.isMenuShowed = value;
    },

    handleToggleJackpots() {
      this.isJackpotsShowed = !this.isJackpotsShowed;
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-header {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  height: $m_header-size;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: background-color 0.5s;

  @media (min-width: 1025px) {
    display: none;
  }

  &--variant\:game {
    top: auto;
    bottom: 100%;
    align-items: normal;

    &::v-deep .nav--right {
      top: 100%;
    }
  }

  &__sweepstakes {
    display: flex;
    align-items: center;
    margin: 0 12px;
    font-family: $base-font-family;
    font-size: $font-sm-size;
    color: $text-color;

    &-item + &-item {
      margin-left: 28px;
    }

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 4px;
    }

    &-title {
      color: $text-color;
      font-family: $base-font-family;
      font-size: $font-xxs-size;
      text-transform: uppercase;
    }

    &-value {
      color: $text-color;
      font-size: $font-xm-size;
      font-family: $base-font-family;
      text-transform: uppercase;
    }
  }

  &__demo,
  &__balance {
    margin: 0 12px;
    font-family: $base-font-family;
    font-size: $font-xm-size;
    color: $text-color;
  }
}
</style>
